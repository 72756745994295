import React, { FunctionComponent, MouseEventHandler } from "react"
import { useIntl } from "react-intl"
import { SearchResultsVariant } from "../search-results/search-results"
import { PodcastEpisodeFields } from "../../../../types/algolia"
import { ListItemPodcastEpisodes } from "../../../../components/list/list"
import { ListItemPodcastEpisode } from "../../../../components/list/list-item-podcast-episode"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import { mapTeachers, mapPodcastEpisodeFieldsMeta } from "./search-helpers"
import styles from "./search-hits.module.scss"

export interface SearchHitPodcastEpisodeProps {
  fields: PodcastEpisodeFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitPodcastEpisode: FunctionComponent<
  SearchHitPodcastEpisodeProps
> = ({ fields, variant, onClick }) => {
  const { formatDate } = useIntl()
  const authors = mapTeachers(fields.teachers)
  const url = fields.podcastShow
    ? `${PathPrefix.Podcasts}/${fields.podcastShow.fields.slug}/${fields.slug}`
    : PathPrefix.Podcasts
  const image = fields.podcastShow
    ? fields.podcastShow.fields.image?.fields?.file.url || ""
    : ""

  const props =
    authors.length === 1
      ? {
          author: `${authors[0].shortName}`,
          avatar: authors[0].image
        }
      : {
          authors: authors
        }

  const meta =
    fields.season || fields.episode
      ? (fields.season
          ? `Season ${fields.season}${fields.episode ? ", " : ""}`
          : "") + (fields?.episode ? "Episode " + fields?.episode : "")
      : formatDate(fields.date, {
          year: "numeric",
          day: "numeric",
          month: "short"
        })

  if (variant === "list") {
    return (
      <ListItemPodcastEpisode
        {...props}
        className={styles.searchHit}
        title={fields.title}
        url={url}
        image={image}
        meta={meta}
        onClick={onClick}
      />
    )
  }

  if (variant === "podcast-episodes-list") {
    return (
      <ListItemPodcastEpisodes
        title={fields.title}
        description={fields.description}
        url={url}
        type="podcastEpisode"
        onClick={onClick}
        meta={meta}
        secondaryMeta={mapPodcastEpisodeFieldsMeta(
          fields,
          fields.podcastShow?.fields.slug || ""
        )}
      />
    )
  }

  return (
    <CardGeneric
      {...props}
      icon="12-audio"
      image={image}
      title={fields.title}
      url={url}
      meta={meta}
      body={fields.description}
      onClick={onClick}
      variant="withBg"
      bgColor={fields.podcastShow?.fields?.color}
    />
  )
}
