import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Avatar } from "../avatar/avatar"
import styles from "./card-author.module.scss"
import { CardLink } from "./card-link"
import { Markdown } from "@components/markdown/markdown"

export interface CardAuthorProps {
  avatar?: string
  className?: string
  description: string
  name: string
  url: string
  onClick?: MouseEventHandler
}

export const CardAuthor: FunctionComponent<CardAuthorProps> = ({
  avatar,
  className,
  description,
  name,
  url,
  onClick
}) => (
  <CardLink
    className={classNames(styles.cardAuthor, className)}
    url={url}
    variant="shadow"
    onClick={onClick}
  >
    <div className={styles.avatar}>
      <Avatar image={avatar} size="lg" />
    </div>
    <div className={styles.textContent}>
      <div className={styles.header}>
        <h2 className={styles.name}>{name}</h2>
      </div>
      <Markdown className={styles.description} content={description} />
    </div>
  </CardLink>
)
