import React, { FunctionComponent, MouseEventHandler } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { ConferenceFields } from "../../../../types/algolia"
import { ListItemConference } from "../../../../components/list/list"
import { CardGeneric } from "../../../../components/card/card-generic"
import { formatDatePeriod } from "../../../../i18n/dates"
import { mapTeachers } from "./search-helpers"

export interface SearchHitConferenceProps {
  fields: ConferenceFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitConference: FunctionComponent<
  SearchHitConferenceProps
> = ({ fields, variant, onClick }) => {
  const body = fields.shortDescription || ""
  const speakers = mapTeachers(fields.speakers || [])
  const image = fields.image?.fields?.file.url || ""
  const date = fields.startDate
    ? formatDatePeriod(fields.startDate, fields?.endDate || null)
    : ""

  if (variant === "fullcards") {
    return (
      <CardGeneric
        authors={speakers}
        body={body}
        icon="12-calendar"
        image={image}
        meta={date}
        title={fields.title}
        url={fields.url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemConference
      date={date}
      title={fields.title}
      image={image}
      speakers={speakers}
      url={fields.url}
      onClick={onClick}
    />
  )
}
