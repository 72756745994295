import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { AvatarSkeleton } from "../avatar/avatar-skeleton"
import { Card } from "./card"
import styles from "./card-article.module.scss"
import { Skeleton } from "@components/skeleton/skeleton"
import skeletonStyles from "@components/skeleton/skeleton.module.scss"

export const CardArticleSkeleton: FunctionComponent = () => (
  <Card className={styles.cardArticle} variant="shadow">
    <div
      className={classNames(
        styles.image,
        styles.skeleton,
        skeletonStyles.background
      )}
    />
    <div className={styles.content}>
      <div className={styles.header}>
        <Skeleton />
      </div>
      <div className={styles.textContent}>
        <h2 className={styles.title}>
          <Skeleton />
        </h2>
        <div className={styles.body}>
          <Skeleton count={2} width="100%" />
        </div>
      </div>
      <div className={styles.author}>
        <AvatarSkeleton size="xs" showName={true} />
      </div>
    </div>
  </Card>
)
