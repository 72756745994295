import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import { TeacherBase } from "../../features/teachers/teacher-types"
import { CardLink } from "./card-link"
import styles from "./card-article.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface CardArticleProps {
  authors: TeacherBase[]
  body: string
  image?: string
  time: number
  title: string
  url: string
  onClick?: MouseEventHandler
}

export const CardArticle: FunctionComponent<CardArticleProps> = ({
  authors,
  body,
  image,
  time,
  title,
  url,
  onClick
}) => {
  const fluid = getFluidGatsbyImage(image || "", {
    maxWidth: 1320,
    sizes: `(min-width: ${styles.mobileMaxWidth}) 220px, 1320px`
  })

  return (
    <CardLink
      className={styles.cardArticle}
      url={url}
      variant="shadow"
      onClick={onClick}
    >
      {fluid ? (
        <BackgroundImage fluid={fluid} className={styles.image} />
      ) : (
        <div
          className={classNames(styles.image, styles.placeholder)}
          style={{
            backgroundImage: `url(/assets/image-placeholders/card-logo.svg)`
          }}
        />
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon variant="12-article" className={styles.icon} />
          {!!time && <span className={styles.time}>{time}MIN</span>}
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.body}>{body}</div>
        </div>
        <div className={styles.author}>
          {authors?.length === 1 && (
            <Avatar
              image={authors[0].image}
              name={authors[0].shortName}
              showName
              size="xs"
            />
          )}
          {authors?.length > 1 && (
            <AvatarList backgroundColor="#ffffff" avatars={authors} size="xs" />
          )}
        </div>
      </div>
    </CardLink>
  )
}
