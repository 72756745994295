import React, { FunctionComponent } from "react"
import classNames from "classnames"
import {
  connectStateResults,
  StateResultsProvided
} from "react-instantsearch-core"
import { AlgoliaRecord } from "../../../../types/algolia"
import styles from "./search-heading.module.scss"

interface SearchHeadingProps extends StateResultsProvided<AlgoliaRecord> {
  className?: string
}

const SearchHeadingUI: FunctionComponent<SearchHeadingProps> = ({
  searchResults,
  searchState,
  className
}) => {
  const resultCount = searchResults?.nbHits
  const pageNum = searchState?.page
  const query = searchState?.configure?.query

  return (
    <div className={classNames(className, styles.searchHeading)}>
      {query && <h2>{query}</h2>}
      <span className={styles.count}>
        {resultCount} results &mdash; page {pageNum}
      </span>
    </div>
  )
}

export const SearchHeading = connectStateResults(SearchHeadingUI)
