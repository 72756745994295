import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./product-variants.module.scss"

export interface ProductVariantsListProps {
  className?: string
}

export const ProductVariantsList: FunctionComponent<
  ProductVariantsListProps
> = ({ children, className }) => (
  <ul className={classNames(styles.variantsList, className)}>{children}</ul>
)
