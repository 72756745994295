import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface ListItemMediaResourceProps extends ListItemProps {
  author: string
  title: string
  authorImage: string | undefined
  image: string | undefined
  time: string | undefined
  onClick?: MouseEventHandler
}

export const ListItemMediaResource: FunctionComponent<
  ListItemMediaResourceProps
> = ({
  author,
  disabled = false,
  authorImage,
  image,
  title,
  url,
  time,
  onClick
}) => {
  const fluid = getFluidGatsbyImage(image || "", {
    maxWidth: 800
  })

  return (
    <ListItem
      className={classNames(styles.listItemMediaResource)}
      disabled={disabled}
      url={url}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>
          <Icon variant="12-video" className={styles.icon} />
          {time && <span className={styles.time}>{time}</span>}
        </div>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.subheader}>
          <Avatar image={authorImage} name={author} showName size="xs" />
        </div>
      </div>
      <div className={styles.side}>
        {fluid ? (
          <BackgroundImage fluid={fluid} className={styles.image} />
        ) : (
          <div
            className={classNames(styles.image, !image && styles.placeholder)}
            style={{
              backgroundImage: `url(${
                image || "/assets/image-placeholders/list-logo.svg"
              })`
            }}
          />
        )}
      </div>
    </ListItem>
  )
}
