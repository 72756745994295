import React, { FunctionComponent, useRef } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { ListItemProductSkeleton } from "@components/list/list-item-product-skeleton"
import { CardArticleSkeleton } from "@components/card/card-article-skeleton"
import { CardAuthorSkeleton } from "@components/card/card-author-skeleton"
import { CardProductSkeleton } from "@components/card/card-product-skeleton"
import { CardGenericSkeleton } from "@components/card/card-generic-skeleton"

const fullCardsVariantComponents = [
  CardArticleSkeleton,
  CardAuthorSkeleton,
  CardProductSkeleton,
  CardGenericSkeleton
]

const cardsVariantComponents = [ListItemProductSkeleton]

const getRandomCardSkeleton = (variant: SearchResultsVariant) => {
  const componentsList =
    variant === "fullcards"
      ? fullCardsVariantComponents
      : cardsVariantComponents

  const randomIndex = Math.floor(Math.random() * componentsList.length)

  return componentsList[randomIndex]
}

interface SearchHitsSkeletonProps {
  variant: SearchResultsVariant
}

export const SearchHitsSkeleton: FunctionComponent<SearchHitsSkeletonProps> = ({
  variant
}) => {
  // Use between 4-6 skeleton cards of random variations; only calculate once on mount
  const items = useRef(
    Array(4 + Math.floor(Math.random() * 3))
      .fill(null)
      .map(() => getRandomCardSkeleton(variant))
  )

  return (
    <>
      {items.current.map((RandomComponent, i) => (
        <RandomComponent key={`search-hits-skeleton-${i}`} variant={variant} />
      ))}
    </>
  )
}
