import React, { FunctionComponent, MouseEventHandler } from "react"
import removeMd from "remove-markdown"
import { TeacherFields } from "../../../../types/algolia"
import { ListItemTeacher } from "../../../../components/list/list"
import { PathPrefix } from "../../../../core/constants"
import { SearchResultsVariant } from "../search-results/search-results"
import { CardAuthor } from "../../../../components/card/card-author"

export interface SearchHitTeacherProps {
  fields: TeacherFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitTeacher: FunctionComponent<SearchHitTeacherProps> = ({
  fields,
  variant,
  onClick
}) => {
  const teacherName = `${fields.shortName}`
  const image = fields.image?.fields?.file.url || ""
  const bio = fields.shortBio || ""
  const url = `${PathPrefix.Teachers}/${fields.slug}`

  if (variant === "fullcards") {
    return (
      <CardAuthor
        name={teacherName}
        avatar={image}
        description={bio}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemTeacher
      title={teacherName}
      image={image}
      meta={bio}
      url={url}
      onClick={onClick}
    />
  )
}
