import React, { FunctionComponent, MouseEventHandler } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { SeriesFields } from "../../../../types/algolia"
import { CardListItem } from "../card-list-item/card-list-item"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import { messageCount } from "../../../../core/utils"
import { mapTeachers } from "./search-helpers"

export interface SearchHitSeriesProps {
  fields: SeriesFields
  forceList?: boolean
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitSeries: FunctionComponent<SearchHitSeriesProps> = ({
  fields,
  forceList = false,
  variant,
  onClick
}) => {
  const authors = mapTeachers(fields.teachers)
  const author = authors.length > 0 ? authors[0] : null
  const authorName = `${author?.shortName}`
  const image = fields.image?.fields?.file.url || ""
  const messages = fields.mediaResources
  const body = fields.description ? fields.description : ""

  let url = `${PathPrefix.Series}/${fields.slug}`

  if (fields.seriesType === "Conference Messages") {
    url = `${PathPrefix.Conferences}/${fields.slug}`
  }

  const props =
    authors.length === 1
      ? {
          avatar: author?.image,
          author: authorName
        }
      : {
          authors: authors
        }

  if (variant === "fullcards") {
    const props =
      authors.length === 1
        ? {
            avatar: author?.image,
            author: authorName
          }
        : {
            authors: authors
          }

    return (
      <CardGeneric
        {...props}
        body={body}
        icon="12-collection"
        image={image}
        meta={messageCount(messages)}
        title={fields.name}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <CardListItem
      {...props}
      messageCount={messages?.length || 0}
      image={image}
      title={fields.name}
      url={url}
      forceList={forceList}
      onClick={onClick}
    />
  )
}
