import React, { FunctionComponent, MouseEventHandler, useMemo } from "react"
import { ProductFields, ProductVariant } from "../../../../types/algolia"
import { SearchResultsVariant } from "../search-results/search-results"
import { Store } from "../../../../core/constants"
import { formatVariantTitle } from "../../../store/store-helpers"
import { useShopifyProduct } from "../../../../hooks/useShopifyProduct"
import { getCustomAttributes } from "../../../../utils/shopify-buy"
import { Availability } from "../../../store/product-availability-source"
import { mapTeachers } from "./search-helpers"
import { useNewCart } from "@contexts/newCartContext"
import { CardProduct } from "@components/card/card-product"
import { CardProductSkeleton } from "@components/card/card-product-skeleton"
import { ListItemProduct } from "@components/list/list-item-product"
import { ListItemProductSkeleton } from "@components/list/list-item-product-skeleton"
import { GridItemProduct } from "@components/grid/grid-item-product"
import { GridItemProductSkeleton } from "@components/grid/grid-item-product-skeleton"
import buildImageUrl from "@utils/imageUrl"

export interface SearchHitProductProps {
  description: string
  fields: ProductFields
  image?: {
    originalSrc: string
  } | null
  title?: string
  primaryVariation?: ProductVariant
  category?: string
  products: ProductVariant[]
  variant: SearchResultsVariant
  authors?: string[]
  onClick?: MouseEventHandler
  onSearchConversion?: () => void
}

export const SearchHitProduct: FunctionComponent<SearchHitProductProps> = ({
  fields,
  title,
  description,
  primaryVariation,
  category,
  products,
  variant,
  onClick,
  onSearchConversion,
  ...rest
}) => {
  const [, sendCartEvent] = useNewCart()

  /*
   * If algolia does not return any teachers use the authors field
   * instead. Authors is an array of strings. No shortname, longname , etc...
   * so we will asign shortname to it so it will work in the components
   * */

  const teachers =
    fields.teachers && fields.teachers.length
      ? mapTeachers(fields.teachers)
      : (rest?.authors || []).map((a) => ({
          shortName: a,
          firstName: a,
          lastName: a
        })) || []

  const product = useMemo(() => {
    if (primaryVariation) {
      return primaryVariation
    }

    if (category) {
      return products.find(
        (item) =>
          item.productType === category || item.productFormat === category
      )
    }
  }, [category, primaryVariation, products])

  const handle = product?.handle

  const { isLoading, error, data } = useShopifyProduct(handle || "")

  if (isLoading) {
    switch (variant) {
      case "fullcards":
        return <CardProductSkeleton />
      case "cards":
        return <GridItemProductSkeleton />
      default:
        return <ListItemProductSkeleton />
    }
  }

  if (!product || error) {
    return null
  }

  const shopProduct = data?.product
  const availability = data?.availability ?? Availability.unknown

  const defaultVariant = shopProduct?.variants?.[0]
  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""

  const handleAddClick = async (id: string) => {
    sendCartEvent("ADD_LINE_ITEM", {
      data: {
        variantId: defaultVariant?.storefrontId || id || "",
        customAttributes: getCustomAttributes(
          {
            ...shopProduct,
            title,
            availability,
            teachers
          },
          {
            color: shopProduct?.color?.value,
            language: shopProduct?.language?.value,
            title: shopProduct?.format?.value,
            quantityAvailable: defaultVariant?.quantityAvailable,
            requiresShipping: defaultVariant?.requiresShipping
          }
        )
      }
    })
  }

  const format = formatVariantTitle(product.productFormat || "")

  const url = `${Store}/${product.handle}`
  const imageUrl =
    product.featuredImage?.originalSrc ||
    defaultVariant?.image?.originalSrc ||
    ""

  const transformedImageUrl = buildImageUrl(imageUrl || "", { width: 400 })

  switch (variant) {
    case "fullcards":
      // only search results
      return (
        <CardProduct
          body={description}
          currentVariant={{
            ...product,
            storefrontId: defaultVariant?.storefrontId || product?.storefrontId,
            price,
            compareAtPrice,
            handle: shopProduct?.handle || handle || ""
          }}
          shopifyProduct={data}
          variants={products}
          image={transformedImageUrl}
          onAddClick={handleAddClick}
          onSearchConversion={onSearchConversion}
          teachers={teachers}
          title={title || ""}
          url={url}
          onClick={onClick}
        />
      )

    case "cards":
      return (
        <GridItemProduct
          productHandle={handle || ""}
          image={transformedImageUrl}
          teachers={teachers}
          title={title || ""}
          type={format}
          url={url}
          onClick={onClick}
          onSearchConversion={onSearchConversion}
        />
      )

    default:
      // category pages
      return (
        <ListItemProduct
          productHandle={handle || ""}
          image={transformedImageUrl}
          teachers={teachers}
          title={title || ""}
          type={format}
          url={url}
          variant="regular"
          onClick={onClick}
          onSearchConversion={onSearchConversion}
        />
      )
  }
}
