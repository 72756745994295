import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"

export interface ListItemPodcastShowProps extends ListItemProps {
  avatar?: string
  author?: string
  authors?: AvatarListItemProps[]
  meta: string
  image?: string
  title: string
  onClick?: MouseEventHandler
}

export const ListItemPodcastShow: FunctionComponent<
  ListItemPodcastShowProps
> = ({
  avatar,
  author,
  authors,
  className,
  meta,
  disabled = false,
  image,
  title,
  url,
  onClick
}) => (
  <ListItem
    className={classNames(styles.listItemPodcastShow, className)}
    disabled={disabled}
    url={url}
    onClick={onClick}
  >
    <div className={styles.content}>
      <div className={styles.subheader}>
        <Icon variant="12-audio" className={styles.icon} />
        <span className={styles.meta}>{meta}</span>
      </div>
      <h2 className={styles.header}>{title}</h2>
      <div className={styles.subheader}>
        {author && <Avatar image={avatar} name={author} showName size="xs" />}
        {authors && (
          <AvatarList
            backgroundColor="#ffffff"
            avatars={authors}
            size="xs"
            limit={4}
          />
        )}
      </div>
    </div>
    <div className={styles.side}>
      <div
        className={classNames(styles.image, !image && styles.placeholder)}
        style={{
          backgroundImage: `url(${
            image || "/assets/image-placeholders/list-logo.svg"
          })`
        }}
      />
    </div>
  </ListItem>
)
