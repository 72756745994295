import { MutableRefObject, RefObject, useEffect } from "react"

export function useClickOutside<T extends HTMLElement>(
  ref: MutableRefObject<T> | RefObject<T>,
  callback: () => void
): void {
  useEffect(() => {
    const handleClick: EventListener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      callback()
    }

    document.addEventListener("click", handleClick)

    return (): void => {
      document.removeEventListener("click", handleClick)
    }
  }, [ref, callback])
}
