import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import Image from "gatsby-image"
import { Icon, IconVariant } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import { CardLink } from "./card-link"
import styles from "./card-generic.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import { Markdown } from "@components/markdown/markdown"
import { resourceIconVariant, ResourceType } from "@src-types/resource"

export interface CardGenericProps {
  avatar?: string
  author?: string
  authors?: AvatarListItemProps[]
  body?: string
  icon?: IconVariant
  image?: string
  meta?: string
  subtitle?: string
  title: string
  url: string
  onClick?: MouseEventHandler
  variant?: "withBg" | "noBg"
  bgColor?: string
  typeForIcon?: ResourceType
}

export const CardGeneric: FunctionComponent<CardGenericProps> = ({
  avatar,
  author,
  authors,
  body = "",
  icon = "12-article",
  image,
  meta,
  subtitle,
  title,
  url,
  onClick,
  bgColor = "#F8F8F7",
  variant = "noBG",
  typeForIcon
}) => {
  const fluid = getFluidGatsbyImage(image || "", {
    maxWidth: 1320,
    sizes: `(min-width: ${styles.mobileMaxWidth}) 220px, 1320px`
  })

  return (
    <CardLink
      className={styles.cardGeneric}
      url={url}
      variant="shadow"
      onClick={onClick}
    >
      {fluid ? (
        variant === "withBg" ? (
          <div className={styles.image} style={{ backgroundColor: bgColor }}>
            <div className={styles.thumbnail}>
              <Image fluid={fluid} />
            </div>
          </div>
        ) : (
          <BackgroundImage fluid={fluid} className={styles.image} />
        )
      ) : (
        <div
          className={classNames(styles.image, styles.placeholder)}
          style={{
            backgroundImage: `url(/assets/image-placeholders/card-logo.svg)`
          }}
        />
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon
            variant={typeForIcon ? resourceIconVariant[typeForIcon] : icon}
            className={styles.icon}
          />
          {meta && <span className={styles.meta}>{meta}</span>}
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <Markdown className={styles.body} content={body} />
        </div>
        <div className={styles.additionalContent}>
          {author && <Avatar image={avatar} name={author} showName size="xs" />}
          {authors && (
            <AvatarList
              backgroundColor="#ffffff"
              avatars={authors}
              size="xs"
              limit={4}
            />
          )}
          {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        </div>
      </div>
    </CardLink>
  )
}
