import React, { FunctionComponent, MouseEventHandler } from "react"
import { readTime } from "../../../../core/utils"
import { FaqFields } from "../../../../types/algolia"
import { PathPrefix } from "../../../../core/constants"
import { SearchResultsVariant } from "../search-results/search-results"
import { ListItemGeneric } from "@components/list/list"
import { CardGeneric } from "@components/card/card-generic"

export interface SearchHitQAProps {
  fields: FaqFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitFaq: FunctionComponent<SearchHitQAProps> = ({
  fields,
  variant,
  onClick
}) => {
  const title = fields.question
  const topic = fields.topic
  const body = fields.answer
  const url = `${PathPrefix.FAQs}/${fields.slug}`
  const time = readTime(body)

  if (variant === "fullcards") {
    return (
      <CardGeneric
        icon="12-video"
        title={title}
        url={url}
        body={body}
        meta={topic}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemGeneric
      title={title}
      url={url}
      meta={`${time}MIN`}
      onClick={onClick}
    />
  )
}
