import React, { FunctionComponent, useEffect, useState } from "react"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { ClearRefinements } from "react-instantsearch-dom"
import { Button } from "../../../../components/button/button"
import { SearchFilters, SearchIndexes } from "../../search-types"
import { SearchFilter } from "../search-filter/search-filter"
import { SearchSortMobile } from "../search-sort/search-sort"
import styles from "./search-panel.module.scss"

export type SearchPanelState = "filter" | "sort" | null

export interface SearchPanelProps {
  className?: string
  defaultIndex: string
  filters: SearchFilters
  indexes: SearchIndexes
  hideSort?: boolean
  onTeachingSeries?: boolean
}

export const SearchPanel: FunctionComponent<SearchPanelProps> = ({
  className,
  defaultIndex,
  filters,
  indexes,
  hideSort
}) => {
  const { formatMessage } = useIntl()
  const [panelState, setPanelState] = useState<SearchPanelState>(null)
  const openFilterPanel = (): void => setPanelState("filter")
  const openSortPanel = (): void => setPanelState("sort")
  const closePanel = (): void => setPanelState(null)
  const hidePanel = !panelState

  useEffect(() => {
    document.body.classList[hidePanel ? "remove" : "add"](styles.disableScroll)
  }, [hidePanel])

  const shouldDisplayFilters = filters.length > 0
  const shouldDisplayIndexes = indexes.length > 1 && !hideSort

  if (!shouldDisplayFilters && !shouldDisplayIndexes) {
    return null
  }

  return (
    <div className={className}>
      <div className={styles.searchPanelButtons}>
        {shouldDisplayFilters && (
          <button
            className={styles.searchPanelButton}
            onClick={openFilterPanel}
          >
            <FormattedMessage id="search-panel-filter" />
          </button>
        )}
        {shouldDisplayIndexes && (
          <button className={styles.searchPanelButton} onClick={openSortPanel}>
            <FormattedMessage id="search-panel-sort" />
          </button>
        )}
      </div>
      <div
        className={classNames([styles.searchPanel, hidePanel && styles.hide])}
      >
        <header className={styles.searchPanelHeader}>
          <h3 className={styles.searchPanelTitle}>
            {panelState && (
              <FormattedMessage id={`search-panel-${panelState}`} />
            )}
          </h3>
          <ClearRefinements
            className={styles.searchPanelResetButton}
            translations={{
              reset: formatMessage({ id: "search-panel-reset" })
            }}
          />
          <Button size="sm" onClick={closePanel}>
            <FormattedMessage id="search-panel-close" />
          </Button>
        </header>
        <div className={styles.searchPanelContent}>
          {shouldDisplayFilters && (
            <SearchFilter
              className={classNames(panelState !== "filter" && styles.hide)}
              filters={filters}
            />
          )}
          {shouldDisplayIndexes && (
            <SearchSortMobile
              className={classNames(panelState !== "sort" && styles.hide)}
              defaultIndex={defaultIndex}
              indexes={indexes}
            />
          )}
        </div>
      </div>
    </div>
  )
}
