import React, { FunctionComponent } from "react"
import { connectSortBy } from "react-instantsearch-dom"
import { FormattedMessage } from "react-intl"
import { SearchSortItems, SearchSortProps } from "./search-sort-types"
import styles from "./search-sort.module.scss"

export interface MobileSortByProps {
  className?: string
  items: SearchSortItems
  refine: (index: string) => void
}

export const MobileSortBy: FunctionComponent<MobileSortByProps> = ({
  className,
  items,
  refine
}) => (
  <form className={className}>
    {items.map(({ label, value, isRefined }, index) => (
      <div key={index} className={styles.searchSortMobileRow}>
        <label>
          <input
            className={styles.searchSortMobileRadio}
            type="radio"
            value={value}
            checked={isRefined}
            onChange={(): void => refine(value)}
          />
          <span className={styles.searchSortMobileLabel}>
            <FormattedMessage id={label} />
          </span>
        </label>
      </div>
    ))}
  </form>
)

export const ConnectedMobileSortBy = connectSortBy(MobileSortBy)

export type SearchSortMobileProps = SearchSortProps

export const SearchSortMobile: FunctionComponent<SearchSortMobileProps> = ({
  className,
  defaultIndex,
  indexes
}) => (
  <ConnectedMobileSortBy
    className={className}
    defaultRefinement={defaultIndex}
    items={indexes}
  />
)
