import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import { Icon } from "../icon/icon"
import { CardLink } from "./card-link"
import styles from "./card-article.module.scss"
import { PageType } from "./card-constants"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import { formatLink } from "@features/store/store-helpers"
import { TeacherBase } from "@features/teachers/teacher-types"
import { Avatar } from "@components/avatar/avatar"
import { AvatarList } from "@components/avatar/avatar-list"

export interface CardPageProps {
  authors?: TeacherBase[]
  body: string
  image?: string
  title: string
  url: string
  pageType: PageType
  onClick?: MouseEventHandler
  previewCopy?: string
}

export const CardPage: FunctionComponent<CardPageProps> = ({
  body,
  image,
  title,
  url,
  pageType,
  onClick,
  previewCopy,
  authors
}) => {
  const fluid = getFluidGatsbyImage(image || "", {
    maxWidth: 1620,
    sizes: `(min-width: ${styles.mobileMaxWidth}) 220px, 1620px`
  })

  const formattedLink = formatLink(url)

  const metaInformation = pageType === "externalLink" ? formattedLink : "Page"

  const IconVariant = pageType === "externalLink" ? "12-link" : "12-page"

  return (
    <CardLink
      className={styles.cardArticle}
      url={url}
      variant="shadow"
      onClick={onClick}
    >
      {fluid ? (
        <BackgroundImage fluid={fluid} className={styles.image} />
      ) : (
        <div
          className={classNames(styles.image, styles.placeholder)}
          style={{
            backgroundImage: `url(/assets/image-placeholders/card-logo.svg)`
          }}
        />
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon variant={IconVariant} className={styles.icon} />
          <span className={styles.time}>{metaInformation}</span>
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.body}>{previewCopy || body}</div>
        </div>
        {authors && (
          <div className={styles.author}>
            {authors?.length === 1 && (
              <Avatar
                image={authors[0].image}
                name={authors[0].shortName}
                showName
                size="xs"
              />
            )}
            {authors?.length > 1 && (
              <AvatarList
                backgroundColor="#ffffff"
                avatars={authors}
                size="xs"
              />
            )}
          </div>
        )}
      </div>
    </CardLink>
  )
}
