import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import GatsbyImage from "gatsby-image"
import { Icon } from "../icon/icon"
import { CardLink } from "./card-link"
import styles from "./card-product-collection.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface CardProductCollectionProps {
  body: string
  images: string[]
  onClick?: MouseEventHandler
  title: string
  url: string
}

const getFluidImage = (url: string) =>
  getFluidGatsbyImage(url, {
    maxWidth: 768,
    sizes: `(min-width: ${styles.mobileMaxWidth}) 110px, 50vw`
  })

export const CardProductCollection: FunctionComponent<
  CardProductCollectionProps
> = ({ body, images, onClick, title, url }) => {
  // Padded array; always has 4 elements regardless of image count
  const imageSlots = Array.from({ ...images, length: 4 })

  return (
    <CardLink
      className={classNames(styles.cardProductCollection)}
      variant="shadow"
      url={url}
      onClick={onClick}
    >
      <div className={styles.images}>
        {imageSlots.map((image, idx) => {
          const imageKey = `${title} ${idx} ${image}`
          const fluid = getFluidImage(image)

          return (
            <div key={imageKey} className={styles.imageTile}>
              {fluid && <GatsbyImage fluid={fluid} />}
            </div>
          )
        })}
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon variant="12-tag" className={styles.icon} />
          <span className={styles.format}>COLLECTION</span>
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.body}>{body}</div>
        </div>
      </div>
    </CardLink>
  )
}
