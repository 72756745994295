import React, {
  FunctionComponent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
  MouseEventHandler
} from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { TeacherBase } from "../../features/teachers/teacher-types"
import { ProductVariant } from "../../types/algolia"
import { AvatarList } from "../avatar/avatar-list"
import skeletonStyles from "../skeleton/skeleton.module.scss"
import { ProductPrices } from "./product-prices"
import { ProductFormatsList } from "./product-formats-list"
import styles from "./card-product.module.scss"
import { CardLink } from "./card-link"
import { useImage } from "@hooks/use-image"
import { ShopifyProductHook } from "@hooks/useShopifyProduct"
import { useClickOutside } from "@hooks/use-click-outside"
import buildImageUrl from "@utils/imageUrl"

export interface CardProductProps {
  body: string
  className?: string
  currentVariant: ProductVariant
  variants: ProductVariant[]
  image?: string
  onAddClick?: (id: string) => Promise<void>
  onSearchConversion?: () => void
  teachers: TeacherBase[]
  title: string
  url: string
  shopifyProduct?: ShopifyProductHook
  onClick?: MouseEventHandler
}

export const CardProduct: FunctionComponent<CardProductProps> = ({
  body,
  className,
  currentVariant,
  shopifyProduct,
  variants,
  image,
  onAddClick,
  onSearchConversion,
  teachers,
  title,
  url,
  onClick
}) => {
  const [isListOpen, setListOpen] = useState<boolean>(false)
  const listRef = useRef<HTMLDivElement>(null)
  const otherFormatsCount = variants.length - 1

  const toggleOtherFormatsList = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      setListOpen(!isListOpen)
      event.stopPropagation()
    },
    [isListOpen]
  )

  const closeOtherFormatsList = useCallback(() => setListOpen(false), [])

  useClickOutside(listRef, closeOtherFormatsList)

  const imageUrl = buildImageUrl(image || "", { width: 800 })

  const { loading: imageLoading } = useImage(imageUrl)

  if (variants.length === 0 || !shopifyProduct) {
    return null
  }

  const disableShadow =
    shopifyProduct?.product?.disableDropShadow?.value === "true"

  return (
    <CardLink
      className={classNames(styles.cardProduct, className)}
      variant="shadow"
      url={url}
      onClick={onClick}
    >
      {imageUrl && !imageLoading ? (
        <div
          className={classNames(styles.image, {
            [styles.disableShadow]: disableShadow
          })}
        >
          <img src={imageUrl} alt={title} />
        </div>
      ) : (
        <div
          className={classNames(styles.image, {
            [styles.placeholder]: !imageUrl,
            [skeletonStyles.background]: imageLoading
          })}
        >
          {!imageUrl && (
            <img
              data-src="/assets/image-placeholders/card-logo.svg"
              alt=""
              className="lazyload"
            />
          )}
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <Icon variant="12-tag" className={styles.icon} />
          <span className={styles.format}>{currentVariant.productFormat}</span>
          {otherFormatsCount > 0 && (
            <div className={styles.otherFormats} ref={listRef}>
              <span
                data-testid={`CardProductVariantsDropdown:${currentVariant.handle}`}
                className={styles.otherFormatsLabel}
                onClick={toggleOtherFormatsList}
              >
                <span className={styles.sm}>Also available in</span>
                <span className={styles.xs}>Available</span>
                <Icon variant="12-left-arrow" className={styles.caret} />
              </span>
              {isListOpen && (
                <ProductFormatsList
                  productTitle={title}
                  currentVariant={currentVariant}
                  variants={variants}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.body}>{body}</div>
        </div>
        <div className={styles.meta}>
          <div className={styles.metaContent}>
            {teachers.length === 1 ? (
              <div className={styles.avatar}>
                <Avatar image={teachers[0].image} size="xs" />
                <span className={styles.author}>{teachers[0].shortName}</span>
              </div>
            ) : teachers.length > 1 ? (
              <AvatarList
                backgroundColor="#ffffff"
                listClassName={styles.avatar}
                namesClassName={styles.author}
                size="xs"
                avatars={teachers}
                limit={4}
              />
            ) : null}
            <ProductPrices
              className={styles.prices}
              shopifyProduct={shopifyProduct}
              variant={currentVariant}
              onAddClick={onAddClick}
              onSearchConversion={onSearchConversion}
              shortButtonMessage={true}
              buttonDataTestId={`AddToCartButton:${title}`}
            />
          </div>
        </div>
      </div>
    </CardLink>
  )
}
