import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import styles from "./no-results.module.scss"

export const NoResults: FunctionComponent = () => (
  <div className={styles.noResults}>
    <img src="/assets/no-results.svg" alt="" />
    <h4>
      <FormattedMessage id="search-no-results-header" />
    </h4>
    <p>
      <FormattedMessage id="search-no-results-text" />
    </p>
  </div>
)
