import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./card-product.module.scss"
import { Card } from "./card"
import { Skeleton } from "@components/skeleton/skeleton"
import skeletonStyles from "@components/skeleton/skeleton.module.scss"

export interface CardProductSkeletonProps {
  className?: string
}

export const CardProductSkeleton: FunctionComponent<
  CardProductSkeletonProps
> = ({ className }) => {
  return (
    <Card
      className={classNames(styles.cardProduct, className)}
      variant="shadow"
    >
      <div
        className={classNames(
          styles.image,
          styles.skeleton,
          skeletonStyles.background
        )}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <Skeleton width="20px" />
          <span className={styles.format}>
            <Skeleton />
          </span>
          <div className={styles.otherFormats}>
            <span className={styles.otherFormatsLabel}>
              <Skeleton />
            </span>
          </div>
        </div>
        <div className={styles.textContent}>
          <h2 className={styles.title}>
            <Skeleton />
          </h2>
          <div className={styles.body}>
            <Skeleton />
          </div>
        </div>
        <div className={styles.meta}>
          <div className={styles.metaContent}>
            <Skeleton />
          </div>
        </div>
      </div>
    </Card>
  )
}
