import React, { FunctionComponent, useMemo, useEffect } from "react"
import { connectSortBy } from "react-instantsearch-dom"
import { useIntl } from "react-intl"
import { useSearch } from "../../hooks/use-search"
import { SearchSortProps, SearchSortItem } from "./search-sort-types"
import styles from "./search-sort.module.scss"
import { Dropdown } from "@components/dropdown/dropdown"
import { Option } from "@src-types/dropdown"

export interface CustomSortByProps {
  items: SearchSortItem[]
  refine: (value: string) => void
}

export const CustomSortBy: FunctionComponent<CustomSortByProps> = ({
  items,
  refine
}) => {
  const { searchState, setSearchState } = useSearch()

  const currentItem = useMemo(
    () => items.find((item) => item.isRefined),
    [items]
  )

  const handleChange = (selectedOption: Option<string>) => {
    setSearchState({ ...searchState, sortBy: selectedOption.value })
    refine(selectedOption.value)
  }

  const dropdownOptions = items.map(({ label, value }) => ({
    id: `search-sort-${value}`, // becomes React key
    label,
    value
  }))

  const defaultOption = {
    id: `search-sort-${currentItem?.value}`,
    label: currentItem?.label || "",
    value: currentItem?.value || ""
  }

  return (
    <Dropdown
      className={styles.customSortBy}
      id="search-sort-dropdown"
      name="search-sort-dropdown"
      label="Sort by"
      variant="store"
      options={dropdownOptions}
      defaultOption={defaultOption}
      onChange={handleChange}
    />
  )
}

export const ConnectedSortBy = connectSortBy(CustomSortBy)

export type SearchSortDesktopProps = SearchSortProps

export const SearchSortDesktop: FunctionComponent<SearchSortDesktopProps> = ({
  className,
  defaultIndex,
  indexes
}) => {
  const { formatMessage } = useIntl()
  const localisedSearchIndexes = useMemo(
    () =>
      indexes.map(({ label, value }) => ({
        value,
        label: formatMessage({ id: label })
      })),
    [indexes, formatMessage]
  )

  return (
    <div className={className}>
      <ConnectedSortBy
        defaultRefinement={defaultIndex}
        items={localisedSearchIndexes}
      />
    </div>
  )
}
