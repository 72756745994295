import React, {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import styles from "./text-input.module.scss"

export type TextInputType = "text" | "email" | "password" | "number"

export interface TextInputProps {
  className?: string
  defaultValue?: string
  disabled?: boolean
  icon?: ReactNode
  id: string
  label?: ReactNode
  name: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onClick?: MouseEventHandler<HTMLInputElement>
  placeholder?: string
  readonly?: boolean
  type?: TextInputType
  value?: string
  min?: number
  max?: number
}

export const TextInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<TextInputProps>
>(
  (
    {
      children,
      className,
      defaultValue,
      disabled = false,
      icon,
      id,
      label,
      name,
      onBlur,
      onChange,
      onClick,
      placeholder,
      readonly = false,
      type = "text",
      value,
      ...rest
    },
    forwardedRef
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue || "")
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)
        onChange?.(event)
      },
      [onChange]
    )

    useEffect(() => {
      if (value) {
        setInputValue(value)
      }
    }, [value])

    return (
      <div
        className={classNames(
          styles.textInput,
          disabled && styles.disabled,
          className
        )}
      >
        {label && (
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
        )}
        <div className={styles.wrapper}>
          <input
            className={styles.input}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onClick={onClick}
            placeholder={placeholder}
            readOnly={readonly}
            ref={forwardedRef}
            type={type}
            value={inputValue}
            {...rest}
          />
          {disabled ? (
            <Icon
              className={styles.inputIcon}
              id={id && `text-input-disabled-${id}`}
              variant="16-disabled"
            />
          ) : (
            icon
          )}
        </div>
        {children}
      </div>
    )
  }
)

TextInput.displayName = "TextInput"
