import React, { FunctionComponent } from "react"
import classNames from "classnames"
import styles from "./product-variants.module.scss"
import { SpinnerButton } from "@components/button/spinner-button"
import { Skeleton, SkeletonProps } from "@components/skeleton/skeleton"
import skeletonStyles from "@components/skeleton/skeleton.module.scss"

const SkeletonText: FunctionComponent<SkeletonProps> = (props) => (
  <Skeleton
    dark
    widthRandomness={0}
    className={styles.skeletonText}
    {...props}
  />
)

export const ProductVariantItemSkeleton: FunctionComponent = () => {
  return (
    <li data-testid="ProductVariantItemSkeleton">
      <span className={styles.variantTitle}>
        <SkeletonText width="3rem" />
      </span>
      <div className={styles.prices}>
        <span className={styles.discounted}>
          <SkeletonText width="2rem" />
        </span>
        <SkeletonText width="2rem" />
      </div>
      <SpinnerButton
        className={classNames(
          skeletonStyles.background,
          skeletonStyles.dark,
          styles.skeletonButton
        )}
        processing={false}
        size="xs"
        disabled
      >
        &nbsp;
      </SpinnerButton>
    </li>
  )
}
