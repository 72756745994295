import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { AvatarList, AvatarListItemProps } from "../avatar/avatar-list"
import { ListItem, ListItemProps } from "./list-item"
import styles from "./list.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface ListItemPodcastEpisodeProps extends ListItemProps {
  avatar?: string
  author?: string
  authors?: AvatarListItemProps[]
  title: string
  image?: string
  meta?: string
  onClick?: MouseEventHandler
}

export const ListItemPodcastEpisode: FunctionComponent<
  ListItemPodcastEpisodeProps
> = ({
  avatar,
  author,
  authors,
  disabled = false,
  image,
  title,
  url,
  meta,
  onClick
}) => {
  const fluid = getFluidGatsbyImage(image || "", {
    maxWidth: 800
  })

  return (
    <ListItem
      className={classNames(styles.listItemPodcastEpisode)}
      disabled={disabled}
      url={url}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.subheader}>
          <Icon variant="12-audio" className={styles.icon} />
          {meta && <span className={styles.meta}>{meta}</span>}
        </div>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.subheader}>
          {author && <Avatar image={avatar} name={author} showName size="xs" />}
          {authors && (
            <AvatarList
              backgroundColor="#ffffff"
              avatars={authors}
              size="xs"
              limit={4}
            />
          )}
        </div>
      </div>
      <div className={styles.side}>
        {fluid ? (
          <BackgroundImage fluid={fluid} className={styles.image} />
        ) : (
          <div
            className={classNames(styles.image, !image && styles.placeholder)}
            style={{
              backgroundImage: `url(${
                image || "/assets/image-placeholders/list-logo.svg"
              })`
            }}
          />
        )}
      </div>
    </ListItem>
  )
}
