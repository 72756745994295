import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { AvatarSkeleton } from "../avatar/avatar-skeleton"
import styles from "./card-author.module.scss"
import { Card } from "./card"
import { Skeleton } from "@components/skeleton/skeleton"

export interface CardAuthorSkeletonProps {
  className?: string
}

export const CardAuthorSkeleton: FunctionComponent<CardAuthorSkeletonProps> = ({
  className
}) => (
  <Card className={classNames(styles.cardAuthor, className)} variant="shadow">
    <div className={styles.avatar}>
      <AvatarSkeleton size="lg" />
    </div>
    <div className={styles.textContent}>
      <div className={styles.header}>
        <h2 className={styles.name}>
          <Skeleton />
        </h2>
      </div>
      <div className={styles.description}>
        <Skeleton count={2} />
      </div>
    </div>
  </Card>
)
