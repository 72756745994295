import React, { ReactElement, useCallback } from "react"
import classNames from "classnames"
import { Option } from "../../types/dropdown"
import { Icon } from "../icon/icon"
import textInputStyles from "../text-input/text-input.module.scss"
import styles from "./dropdown.module.scss"

export interface DropdownOptionProps<T> {
  className?: string
  dropdownId?: string
  highlighted: boolean
  option: Option<T>
  onClick: (option: Option<T>) => void
  selected: boolean
  hideIcon?: boolean
}

export function DropdownOption<T = string>({
  className,
  dropdownId,
  highlighted,
  option,
  onClick,
  selected,
  hideIcon
}: DropdownOptionProps<T>): ReactElement {
  const handleClick = useCallback(() => {
    onClick(option)
  }, [option, onClick])

  return (
    <li
      className={classNames(
        styles.option,
        selected && styles.selected,
        option.disabled && styles.disabled,
        highlighted && styles.highlighted,
        className
      )}
      onClick={handleClick}
    >
      {option.label}
      {selected && !hideIcon && (
        <Icon
          className={textInputStyles.inputIcon}
          id={
            dropdownId && `dropdown-${dropdownId}-selected-option-${option.id}`
          }
          variant="12-check"
        />
      )}
    </li>
  )
}
