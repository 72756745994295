import React, { useEffect, useState, ComponentType } from "react"
import {
  connectRefinementList,
  RefinementListProvided
} from "react-instantsearch-core"
import { DynamicProps } from "@src-types/utils"

export interface RefinementItem {
  label: string
  value: string[]
  count: number
  isRefined: boolean
}

export interface RefinementListInterceptorProps extends RefinementListProvided {
  onGetItems(providedProps: Partial<RefinementListProvided>): void
}

const RefinementListInterceptor =
  connectRefinementList<RefinementListInterceptorProps>((props) => {
    const { items, onGetItems, refine, currentRefinement } = props

    const refinedItems = items.filter((item) => item.isRefined)

    useEffect(() => {
      onGetItems?.({
        items,
        refine,
        currentRefinement
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refinedItems.length, items.length])

    return null
  })

interface EnhancedComponentProps extends DynamicProps {
  refinementItems: RefinementItem[]
  refine: RefinementListProvided["refine"]
}

/**
 * Higher-order component providing an Algolia refinement list according to the
 * attribute prop passed to the returned/enhanced component
 *
 * To be used in place of the built-in HOC connectRefinementList() when the wrapped
 * component already renders a <RefinementList>
 *
 * Using the built-in HOC this way causes an inifinite render situation for some
 * reason, which locks up the app - so this HOC implements a workaround to avoid
 * the problem by rendering a component that uses connectRefinementList() as a
 * sibling of the wrapped component. With this workaround, the input component can
 * safely render a <RefinementList> without freezing the app.
 */

export const withRefinementList = <P extends EnhancedComponentProps>(
  InnerComponent: ComponentType<P>
) => {
  const Enhanced: ComponentType<DynamicProps> = (props) => {
    const [providedProps, setProvidedProps] =
      useState<Partial<RefinementListProvided>>()

    return (
      <>
        <RefinementListInterceptor
          attribute={props.attribute}
          onGetItems={setProvidedProps}
        />
        <InnerComponent
          {...(props as P)}
          refinementItems={providedProps?.items}
          refine={providedProps?.refine}
        />
      </>
    )
  }

  return Enhanced
}
