import React, { FunctionComponent, MouseEventHandler } from "react"
import removeMd from "remove-markdown"
import { SearchResultsVariant } from "../search-results/search-results"
import { ArticleFields } from "../../../../types/algolia"
import { CardArticle } from "../../../../components/card/card-article"
import { ListItemArticle } from "../../../../components/list/list"
import { readTime } from "../../../../core/utils"
import { PathPrefix } from "../../../../core/constants"
import { mapTeachers } from "./search-helpers"

export interface SearchHitArticleProps extends Record<string, any> {
  fields: ArticleFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitPost: FunctionComponent<SearchHitArticleProps> = ({
  fields,
  variant,
  onClick,
  ...otherProps
}) => {
  const authors = mapTeachers(fields.teachers)
  const image = fields.headerImage?.fields?.file.url || ""
  const time = readTime(otherProps?.searchBody)
  const body = removeMd(otherProps?.searchBody)
  const url = `${PathPrefix.Post}/${fields.slug}`

  if (variant === "fullcards") {
    return (
      <CardArticle
        authors={authors}
        body={body}
        image={image}
        time={time}
        title={fields.name}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemArticle
      authors={authors}
      time={time}
      title={fields.name}
      image={image}
      url={url}
      onClick={onClick}
    />
  )
}
