import React, { FunctionComponent } from "react"
import { navigate } from "gatsby"
import { Store } from "../../core/constants"
import { ProductVariant } from "../../types/algolia"
import { ProductVariantItem } from "../../features/store/components/product-variants/product-variant-item"
import { ProductVariantsList } from "../../features/store/components/product-variants/product-variants-list"
import styles from "./card-product.module.scss"

export interface ProductFormatsListProps {
  productTitle: string
  currentVariant: ProductVariant
  variants: ProductVariant[]
}

export const ProductFormatsList: FunctionComponent<ProductFormatsListProps> = ({
  currentVariant,
  variants
}) => {
  const handleItemClick = (handle: string) => navigate(`${Store}/${handle}`)

  return (
    <ProductVariantsList className={styles.otherFormatsList}>
      {variants.map((variant) => {
        if (variant.handle === currentVariant.handle) {
          return null
        }

        return (
          <ProductVariantItem
            handle={variant.handle}
            id={variant.id as string}
            key={variant.handle}
            onClick={handleItemClick}
            title={variant.productFormat}
            language={variant.productLanguage}
            storefrontId={variant.storefrontId}
          />
        )
      })}
    </ProductVariantsList>
  )
}
