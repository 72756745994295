import React, { FunctionComponent, MouseEventHandler } from "react"
import { useIntl } from "react-intl"
import { SearchResultsVariant } from "../search-results/search-results"
import { DevotionalFields, DevotionalTeachers } from "../../../../types/algolia"
import { ListItemDevotional } from "../../../../components/list/list"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import {
  ContentfulScriptureReference,
  formatSingularScripture,
  getTranslateableScripture,
  mapScriptureReference
} from "../../../scriptures/mappers/scripture-reference-mapper"
import { getStringTranslator } from "../../../../i18n/helpers"
import { mapTeachers } from "./search-helpers"

export interface SearchHitDevotionalProps {
  fields: DevotionalFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitDevotional: FunctionComponent<
  SearchHitDevotionalProps
> = ({ fields, variant, onClick }) => {
  const intl = useIntl()
  const translateString = getStringTranslator(intl)

  const translateableScripture =
    fields.primaryScriptureReference &&
    fields.primaryScriptureReference.start.book
      ? getTranslateableScripture(
          mapScriptureReference(
            fields.primaryScriptureReference as ContentfulScriptureReference
          )
        )
      : ""

  const source = formatSingularScripture(
    translateString(translateableScripture) as string
  )
  const url = `${PathPrefix.Devotional}/${fields.slug}`
  const body = fields.bodyCopy
  const teachers = fields?.teachers

  if (variant === "fullcards") {
    let additionalInfo
    const isSingularTeacher = teachers && teachers?.length === 1
    const isPluralTeacher = teachers && teachers?.length > 1

    if (isSingularTeacher || isPluralTeacher) {
      const singleTeacherName = teachers?.[0]?.fields?.shortName
      const singleTeacherAvatar =
        teachers?.[0]?.fields?.image?.fields?.file?.url

      additionalInfo = {
        [isSingularTeacher ? "author" : "authors"]: isSingularTeacher
          ? singleTeacherName
          : mapTeachers(teachers as DevotionalTeachers[]),
        avatar: singleTeacherAvatar
      }
    } else {
      additionalInfo = {
        subtitle: source
      }
    }

    return (
      <CardGeneric
        {...(additionalInfo as Record<string, unknown>)}
        icon="12-book"
        body={body}
        title={fields.name}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemDevotional
      source={source}
      title={fields.name}
      url={url}
      teachers={teachers}
      onClick={onClick}
    />
  )
}
