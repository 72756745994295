import React, { FunctionComponent, MouseEventHandler } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { PodcastShowFields } from "../../../../types/algolia"
import { CardGeneric } from "../../../../components/card/card-generic"
import { ListItemPodcastShow } from "../../../../components/list/list-item-podcast-show"
import { PathPrefix } from "../../../../core/constants"
import { mapTeachers } from "./search-helpers"

export interface SearchHitPodcastShowProps {
  fields: PodcastShowFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitPodcastShow: FunctionComponent<
  SearchHitPodcastShowProps
> = ({ fields, variant, onClick }) => {
  const authors = mapTeachers(fields.hosts)
  const author = `${authors[0].shortName}`
  const image = fields.image?.fields?.file.url || ""
  const url = fields.websiteUrl || `${PathPrefix.Podcasts}/${fields.slug}`

  if (variant === "list") {
    const props =
      authors.length === 1
        ? {
            author: `${authors[0].shortName}`,
            avatar: authors[0].image
          }
        : {
            author: `${authors[0].shortName}`,
            authors: authors
          }

    return (
      <ListItemPodcastShow
        {...props}
        meta={"Podcast"}
        title={fields.title}
        image={image}
        url={url}
        onClick={onClick}
      />
    )
  }

  const props =
    authors.length === 1
      ? {
          avatar: authors[0].image,
          author: `${authors[0].shortName}`
        }
      : {
          authors: authors
        }

  return (
    <CardGeneric
      {...props}
      body={fields.description}
      icon="12-audio"
      title={fields.title}
      image={image}
      meta={"podcast"}
      url={url}
      onClick={onClick}
      variant="withBg"
      bgColor={fields.color}
    />
  )
}
