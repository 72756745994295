import React, { FunctionComponent } from "react"
import {
  ListItemProductSkeleton,
  ListItemProductSkeletonProps
} from "../list/list-item-product-skeleton"

export const GridItemProductSkeleton: FunctionComponent<
  ListItemProductSkeletonProps
> = (props) => {
  return <ListItemProductSkeleton {...props} variant="card" />
}
