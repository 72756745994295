import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Link } from "../link/link"
import { CardVariant, CardBackground } from "./card"
import styles from "./card.module.scss"

export interface CardLinkProps {
  background?: CardBackground
  className?: string
  url: string
  variant?: CardVariant
  onClick?: MouseEventHandler
}

export const CardLink: FunctionComponent<CardLinkProps> = ({
  background = "white",
  children,
  className,
  variant = "shadow",
  url,
  onClick
}) => (
  <Link
    className={classNames(
      styles.card,
      styles[background],
      styles[variant],
      styles.interactive,
      className
    )}
    to={url}
    onClick={onClick}
  >
    {children}
  </Link>
)
