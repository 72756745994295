import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState
} from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { MediaResourceFields } from "../../../../types/algolia"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import { ListItemMediaResource } from "../../../../components/list/list-item-media-resource"
import { mapTeachers } from "./search-helpers"
import styles from "./search-hits.module.scss"
import { formatTime } from "@components/audio/helpers"

export interface SearchHitMediaResourceProps {
  fields: MediaResourceFields
  variant: SearchResultsVariant
  videoDuration?: string
  audioUrl?: string
  onClick?: MouseEventHandler
}

export const SearchHitMediaResource: FunctionComponent<
  SearchHitMediaResourceProps
> = ({ fields, variant, videoDuration, audioUrl, onClick }) => {
  const authors = mapTeachers(fields.teachers)
  const author = authors.length > 0 ? authors[0] : null
  const authorName = `${author?.shortName}`
  const image = fields.series?.fields?.image?.fields?.file?.url
  const series = fields.series?.fields
  const seriesType = fields.series?.fields?.seriesType
  const body = fields.description ? fields.description : ""

  const [audioDuration, setAudioDuration] = useState<string | null>(null)

  useEffect(() => {
    // the meta field is usually provided for videos and stores the total duration
    if (typeof window !== "undefined" && !videoDuration) {
      const audio = document.createElement("audio")

      audio.src = audioUrl || ""

      audio.addEventListener("loadedmetadata", function () {
        const duration = audio.duration

        setAudioDuration(formatTime(duration))
      })
    }
  })

  if (!series) {
    return null
  }

  const urlPrefix =
    seriesType === "Conference Messages"
      ? PathPrefix.Conferences
      : PathPrefix.Series
  const url = `${urlPrefix}/${series.slug}/${fields.slug}`

  if (variant === "fullcards") {
    const props =
      authors.length === 1
        ? {
            avatar: author?.image,
            author: authorName
          }
        : {
            authors: authors
          }

    return (
      <CardGeneric
        {...props}
        icon="12-video"
        meta={
          videoDuration
            ? videoDuration
            : audioDuration
            ? audioDuration
            : undefined
        }
        title={fields.name}
        url={url}
        body={body}
        image={image}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemMediaResource
      className={styles.searchHit}
      title={fields.name}
      author={authorName}
      url={url}
      authorImage={author?.image}
      image={image}
      time={
        videoDuration
          ? videoDuration
          : audioDuration
          ? audioDuration
          : undefined
      }
      onClick={onClick}
    />
  )
}
