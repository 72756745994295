import {
  KeyboardEvent,
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import { ValidationStatus } from "../../types/validators"
import { Option } from "../../types/dropdown"
import { Keys, requiredFieldMessage } from "../../core/constants"
import { useClickOutside } from "../../hooks/use-click-outside"
import styles from "./dropdown.module.scss"
import { DropdownProps } from "./dropdown"

const dropdownOptionListMaxHeight = parseInt(
  styles.dropdownOptionListMaxHeight,
  10
)

type UseDropdownParams<T> = Pick<
  DropdownProps<T>,
  | "defaultOption"
  | "onChange"
  | "onError"
  | "onSuccess"
  | "options"
  | "required"
>

interface UseDropdown<T> {
  dropdownRef: RefObject<HTMLDivElement>
  error: string | null
  handleDropdownKeyDown: KeyboardEventHandler
  handleOptionSelect: (option: Option<T>) => void
  handleInputClick: () => void
  highlightedOptionIndex: number
  optionsAbove: boolean
  optionsVisible: boolean
  selectedOption: Option<T> | null
  status: ValidationStatus | null
  textInputRef: RefObject<HTMLInputElement>
}

export const useDropdown = function <T>({
  defaultOption,
  onChange,
  onError,
  onSuccess,
  options,
  required
}: UseDropdownParams<T>): UseDropdown<T> {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const textInputRef = useRef<HTMLInputElement>(null)
  const [pristine, setPristine] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [status, setStatus] = useState<ValidationStatus | null>(
    defaultOption ? "success" : null
  )
  const [optionsVisible, setOptionsVisible] = useState(false)
  const [optionsAbove, setOptionsAbove] = useState(false)
  const [selectedOption, setSelectedOption] = useState<Option<T> | null>(
    defaultOption || null
  )
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1)
  const handleInputClick = useCallback(() => {
    setPristine(false)
    setOptionsVisible((prevOptionsVisible) => !prevOptionsVisible)
  }, [])
  const handleOutsideClick = useCallback(() => {
    if (pristine) {
      return
    }

    setOptionsVisible(false)

    if (required && !selectedOption) {
      setError(requiredFieldMessage)
      setStatus("error")
      onError?.()
    }
  }, [onError, pristine, required, selectedOption])
  const handleOptionSelect = useCallback(
    (option: Option<T>) => {
      if (option.disabled) return

      setSelectedOption(option)
      onChange?.(option)
      setOptionsVisible(false)
      setError(null)
      setStatus("success")
      onSuccess?.(option)
    },
    [onChange, onSuccess]
  )
  const handleDropdownKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (!optionsVisible) {
        return
      }

      const { key } = event

      if (key !== Keys.Down && key !== Keys.Up && key !== Keys.Enter) {
        return
      }

      if (key === Keys.Enter) {
        handleOptionSelect(options[highlightedOptionIndex])

        return
      }

      setHighlightedOptionIndex((previousIndex) => {
        if (key === Keys.Down && previousIndex < options.length - 1) {
          return previousIndex + 1
        }

        if (key === Keys.Down && previousIndex === options.length - 1) {
          return 0
        }

        if (key === Keys.Up && previousIndex > 0) {
          return previousIndex - 1
        }

        return options.length - 1
      })
    },
    [handleOptionSelect, highlightedOptionIndex, options, optionsVisible]
  )

  useClickOutside(dropdownRef, handleOutsideClick)

  useLayoutEffect(() => {
    const handleWindowResize = (): void => {
      const dropdownBoundingRect = dropdownRef.current!.getBoundingClientRect()
      const dropdownBottomOffset =
        window.innerHeight - dropdownBoundingRect.bottom

      setOptionsAbove(dropdownBottomOffset < dropdownOptionListMaxHeight)
    }

    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)

    return (): void => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return {
    dropdownRef,
    error,
    handleDropdownKeyDown,
    handleOptionSelect,
    handleInputClick,
    highlightedOptionIndex,
    optionsAbove,
    optionsVisible,
    selectedOption,
    status,
    textInputRef
  }
}
