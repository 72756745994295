import React, {
  Ref,
  SyntheticEvent,
  FunctionComponent,
  forwardRef
} from "react"
import classNames from "classnames"
import { Icon } from "../icon/icon"
import storeVariantStyles from "./store-variant.module.scss"

export interface StoreDropdownLauncherProps {
  className?: string
  label?: string
  placeholder: string
  onClick?: (e: SyntheticEvent) => void
  ref?: Ref<HTMLDivElement | HTMLInputElement>
}

export const StoreDropdownLauncher: FunctionComponent<StoreDropdownLauncherProps> =
  forwardRef<HTMLDivElement | HTMLInputElement, StoreDropdownLauncherProps>(
    (
      { className, label, placeholder, onClick }: StoreDropdownLauncherProps,
      ref
    ) => (
      <div
        className={classNames(storeVariantStyles.launcher, className)}
        ref={ref}
        onClick={onClick}
        data-testid="SortDropdownTrigger"
      >
        {label && (
          <span
            className={classNames(
              storeVariantStyles.launcherText,
              storeVariantStyles.label
            )}
          >
            {label}
          </span>
        )}
        <span className={storeVariantStyles.launcherText}>{placeholder}</span>
        <Icon variant="16-right-arrow" />
      </div>
    )
  )

StoreDropdownLauncher.displayName = "StoreDropdownLauncher"
