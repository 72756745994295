import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { ClearRefinements } from "react-instantsearch-dom"
import { useIntl } from "react-intl"
import { TopicMap } from "../../../topics/topic-types"
import { SearchFilters, SearchAttribute } from "../../search-types"
import { getBookLocaliser } from "../../../scriptures/mappers/scripture-reference-mapper"
import { SearchFilterGroupListItem } from "./search-filter-group-list-item"
import styles from "./search-filter.module.scss"

export type IdToNameMap = TopicMap

export interface SearchFilterProps {
  className?: string
  filters: SearchFilters
  resetText?: string
  showReset?: boolean
}

export const SearchFilter: FunctionComponent<SearchFilterProps> = ({
  className,
  filters,
  showReset
}) => {
  const { formatMessage } = useIntl()

  const translations: Partial<
    Record<SearchAttribute, IdToNameMap | ((label: string) => string)>
  > = {
    type: (label: string) => formatMessage({ id: `search-type-${label}` }),
    "fields.primaryScriptureReference.start.book":
      getBookLocaliser(formatMessage)
  }

  return (
    <div className={classNames(styles.searchFilter, className)}>
      <ul className={styles.searchFilterGroupList}>
        {filters.map((props, index) => (
          <SearchFilterGroupListItem
            {...props}
            key={index}
            translations={translations[props.attribute] || {}}
          />
        ))}
      </ul>
      {showReset && (
        <ClearRefinements
          translations={{ reset: formatMessage({ id: "search-panel-reset" }) }}
        />
      )}
    </div>
  )
}
