import React, { FunctionComponent, MouseEventHandler } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { QuestionAnsweredFields } from "../../../../types/algolia"
import { ListItemPost } from "../../../../components/list/list"
import { CardGeneric } from "../../../../components/card/card-generic"
import { readTime } from "../../../../core/utils"
import { PathPrefix } from "../../../../core/constants"
import { mapTeachers } from "./search-helpers"
import { ResourceType } from "@src-types/resource"

export interface SearchHitQAProps {
  fields: QuestionAnsweredFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
  typeForIcon?: ResourceType
}

export const SearchHitQA: FunctionComponent<SearchHitQAProps> = ({
  fields,
  variant,
  onClick,
  typeForIcon
}) => {
  const authors = mapTeachers(fields.teachers)
  const author = authors.length > 0 ? authors[0] : null
  const answer = fields.answer
  const authorName = `${author?.shortName}`
  const url = `${PathPrefix.QuestionsAnswered}/${fields.slug}`
  const time = readTime(answer)

  if (variant === "fullcards") {
    const props =
      authors.length === 1
        ? {
            avatar: author?.image,
            author: authorName
          }
        : {
            authors: authors
          }

    return (
      <CardGeneric
        {...props}
        icon="12-article"
        meta={`${time}MIN`}
        title={fields.question}
        body={answer}
        url={url}
        onClick={onClick}
        typeForIcon={typeForIcon}
      />
    )
  }

  return (
    <ListItemPost
      title={fields.question}
      teachers={fields.teachers}
      url={url}
      meta={`${time}MIN`}
      onClick={onClick}
      typeForIcon={typeForIcon}
    />
  )
}
