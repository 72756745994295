import { graphql, useStaticQuery } from "gatsby"

const formatHumanReadable = (rawSeconds: string) => {
  if (!rawSeconds) {
    return ""
  }

  const totalSeconds = Number(rawSeconds)

  if (typeof totalSeconds === "number") {
    const minutes = totalSeconds / 60
    const decimals = minutes % 1
    const seconds = 60 * decimals

    return `${Math.floor(minutes)}:${String(Math.ceil(seconds)).padStart(
      2,
      "0"
    )}`
  }
}

interface IMediaResourceFields {
  brightcoveId: string
  videoDuration: string
}

interface IMediaResourceObj {
  video: IMediaResourceFields | null
}

type IMap = Map<string | undefined, IMediaResourceFields>

export const useMediaResourceMap = (): IMap => {
  const map = new Map()

  const staticQueryResult = useStaticQuery(
    graphql`
      query MediaResource {
        allContentfulMediaResource {
          nodes {
            video {
              brightcoveId
              videoDuration
            }
          }
        }
      }
    `
  )

  const data = staticQueryResult?.allContentfulMediaResource?.nodes || []

  data.forEach((item: IMediaResourceObj) => {
    if (item.video) {
      const id = item.video?.brightcoveId
      const readableDuration = formatHumanReadable(item.video?.videoDuration)

      map.set(id, {
        videoDuration: readableDuration
      })
    }
  })

  return map
}
