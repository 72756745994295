import React, { FunctionComponent, MouseEvent, MouseEventHandler } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import { SpinnerButton } from "../../../../components/button/spinner-button"
import { Availability } from "../../product-availability-source"
import { formatVariantTitle, formatColorLanguage } from "../../store-helpers"
import { useShopifyProduct } from "../../../../hooks/useShopifyProduct"
import { getCustomAttributes } from "../../../../utils/shopify-buy"
import { ProductVariantItemSkeleton } from "./product-variant-item-skeleton"
import styles from "./product-variants.module.scss"
import { useNewCart } from "@contexts/newCartContext"
import { showCompareAtPrice } from "@core/prices"
import { formatPrice } from "@utils/formatPrice"

export interface ProductVariantItemProps {
  availability?: Availability
  compareAtPrice?: string
  handle: string
  id: string
  isProcessing?: boolean
  onAdd?(handle: string): void
  onClick(handle: string): void
  price?: string
  title: string
  format?: string
  language?: string
  color?: string | undefined
  storefrontId?: string
}

export const ProductVariantItem: FunctionComponent<ProductVariantItemProps> = ({
  handle,
  onClick,
  title,
  format,
  language,
  color,
  storefrontId
}) => {
  const [{ context }, sendCartEvent] = useNewCart()

  const { isLoading, error, data } = useShopifyProduct(handle)

  if (isLoading) {
    return <ProductVariantItemSkeleton />
  }

  if (error) {
    return null
  }

  const { itemsBeingAdded } = context

  const shopProduct = data?.product
  const defaultVariant = shopProduct?.variants?.[0]
  const availability = data?.availability ?? Availability.unknown
  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""
  const variantId = defaultVariant?.storefrontId || storefrontId

  const handleItemAdd =
    (itemCanBeOrdered: boolean) => async (ev: MouseEvent<HTMLDivElement>) => {
      ev.preventDefault()
      ev.stopPropagation()

      if (itemCanBeOrdered) {
        sendCartEvent("ADD_LINE_ITEM", {
          data: {
            variantId: variantId || "",
            customAttributes: getCustomAttributes(
              {
                ...shopProduct,
                availability
              },
              {
                color: shopProduct?.color?.value,
                language: shopProduct?.language?.value,
                title: shopProduct?.format?.value,
                quantityAvailable: defaultVariant?.quantityAvailable,
                requiresShipping: defaultVariant?.requiresShipping
              }
            )
          }
        })
      }
    }

  const itemIsBeingAdded = variantId
    ? itemsBeingAdded.includes(variantId)
    : false

  const handleVariantClick: MouseEventHandler = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    onClick(handle)
  }

  const formattedTitle = formatColorLanguage(
    format || formatVariantTitle(title),
    color || shopProduct?.color?.value,
    language || shopProduct?.language?.value
  )
  const itemCanBeOrdered = [
    Availability.available,
    Availability.backorder,
    Availability.preorder
  ].includes(availability)

  return (
    <li
      data-testid="ProductVariantItem"
      key={handle}
      onClick={handleVariantClick}
    >
      <span className={styles.variantTitle}>{formattedTitle}</span>
      <div className={styles.prices}>
        {showCompareAtPrice(price, compareAtPrice) && (
          <span className={classNames(compareAtPrice && styles.discounted)}>
            {formatPrice(compareAtPrice)}
          </span>
        )}
        <span>{formatPrice(price)}</span>
      </div>
      <div
        data-testid={`ProductVariantAddToCart:${formattedTitle}`}
        onClick={handleItemAdd(itemCanBeOrdered)}
        className={classNames([
          "addToCart",
          !itemCanBeOrdered && styles.cursorNotAllowed
        ])}
      >
        {/* can't get SpinnerButton to active click in jest test :/ */}
        <SpinnerButton
          size="xs"
          processing={availability === Availability.unknown || itemIsBeingAdded}
          disabled={!itemCanBeOrdered}
        >
          <FormattedMessage id="store-add" />
        </SpinnerButton>
      </div>
    </li>
  )
}
