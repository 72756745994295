import React, { FunctionComponent, MouseEventHandler } from "react"
import { useIntl } from "react-intl"
import { SearchResultsVariant } from "../search-results/search-results"
import { SermonFields } from "../../../../types/algolia"
import { ListItemSermon } from "../../../../components/list/list"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import {
  ContentfulScriptureReference,
  getTranslateableScripture,
  mapScriptureReference
} from "../../../scriptures/mappers/scripture-reference-mapper"
import { getStringTranslator } from "../../../../i18n/helpers"
import { Avatar } from "../../../../components/avatar/avatar"
import { mapAuthor } from "./search-helpers"

export interface SearchHitSermonProps {
  fields: SermonFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitSermon: FunctionComponent<SearchHitSermonProps> = ({
  fields,
  variant,
  onClick
}) => {
  const intl = useIntl()
  const translateString = getStringTranslator(intl)

  const author = mapAuthor(fields.teachers)
  const authorName = `${author.shortName}`

  const translateableScripture =
    fields.primaryScriptureReference &&
    fields.primaryScriptureReference.start.book
      ? getTranslateableScripture(
          mapScriptureReference(
            fields.primaryScriptureReference as ContentfulScriptureReference
          )
        )
      : ""

  const source = translateString(translateableScripture) as string
  const url = `${PathPrefix.Sermon}/${fields.slug}`

  const placeholderBody = `A sermon by R.C. Sproul on ${source}.`
  const body =
    fields?.sermonSynopsis || fields?.sermonTranscript || placeholderBody

  if (variant === "fullcards") {
    return (
      <CardGeneric
        avatar={author.image}
        author={authorName}
        body={body}
        icon="12-audio"
        meta={source}
        title={fields.sermonTitle}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemSermon
      subtitle={
        <Avatar image={author.image} name={authorName} showName size="xs" />
      }
      meta={source}
      title={fields.sermonTitle}
      url={url}
      onClick={onClick}
    />
  )
}
