import React, { FunctionComponent } from "react"
import styles from "./algoliaCheckbox.module.scss"

interface AngoliaStyleCheckboxProps {
  checked: boolean
  title: string
}

const AngoliaStyleCheckbox: FunctionComponent<AngoliaStyleCheckboxProps> = ({
  checked,
  title
}) => {
  return (
    <div className={styles.container}>
      <input name={title} type="checkbox" checked={checked} />
      <label className={styles.label} htmlFor={title}>
        {title}
      </label>
      <span className={styles.checkmark}></span>
    </div>
  )
}

export default AngoliaStyleCheckbox
