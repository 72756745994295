import React, {
  FunctionComponent,
  createContext,
  useEffect,
  useContext
} from "react"
import {
  connectStateResults,
  connectPagination,
  StateResultsProvided
} from "react-instantsearch-core"
import { noop } from "../../../../core/utils"
import { List } from "../../../../components/list/list"
import { Button } from "../../../../components/button/button"
import {
  ArticleFields,
  SermonFields,
  SeriesFields,
  QuestionAnsweredFields,
  DevotionalFields,
  CollectionFields,
  ConferenceFields,
  TeacherFields,
  MediaResourceFields,
  AlgoliaRecord,
  PodcastShowFields,
  PodcastEpisodeFields,
  ProductFields,
  FaqFields,
  PageFields
} from "../../../../types/algolia"
import { useMediaResourceMap } from "../../../teaching-series/mappers/media-resource-mapper"
import { SearchResultsVariant } from "../search-results/search-results"
import { NoResults } from "../no-results/no-results"
import { algoliaAnalytics } from "../../search-constants"
import { SearchResultsExtended } from "../../search-types"
import { SearchHitArticle } from "./search-hit-article"
import { SearchHitPost } from "./search-hit-post"
import { SearchHitSermon } from "./search-hit-sermon"
import { SearchHitSeries } from "./search-hit-series"
import { SearchHitQA } from "./search-hit-qa"
import { SearchHitDevotional } from "./search-hit-devotional"
import { SearchHitCollection } from "./search-hit-collection"
import { SearchHitConference } from "./search-hit-conference"
import { SearchHitTeacher } from "./search-hit-teacher"
import { SearchHitMediaResource } from "./search-hit-media-resource"
import { SearchHitProduct } from "./search-hit-product"
import { SearchHitPodcastShow } from "./search-hit-podcast-show"
import { SearchHitPodcastEpisode } from "./search-hit-podcast-episode"
import { SearchHitPage } from "./search-hit-page"
import styles from "./search-hits.module.scss"
import {
  SearchHitProductCollection,
  SearchHitProductCollectionProps
} from "./search-hit-product-collection"
import { SearchHitFaq } from "./search-hit-faq"
import { SearchHitsSkeleton } from "./search-hits-skeleton"

interface ContextProps {
  variant: SearchResultsVariant
  category?: string
  onDisplay?: (hits: AlgoliaRecord[]) => void
}

export interface SearchHitsProps extends ContextProps {
  className?: string
}

const PropsContext = createContext<ContextProps>({
  variant: "list",
  category: "",
  onDisplay: noop
})

export const Hits: FunctionComponent<StateResultsProvided<AlgoliaRecord>> = ({
  searchResults,
  searching,
  searchingForFacetValues,
  isSearchStalled,
  error
}) => {
  const { variant, category, onDisplay } = useContext(PropsContext)
  const { hits = [] } = searchResults || {}

  useEffect(() => {
    if (onDisplay && hits.length) {
      onDisplay(hits as unknown as AlgoliaRecord[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hits.length])

  const isLoading = searching || searchingForFacetValues || isSearchStalled

  const mediaResourceMap = useMediaResourceMap()

  if (isLoading) {
    return (
      <List
        dataTestId="SearchHitsList"
        variant="results"
        ordered={variant === "ordered-list"}
      >
        <SearchHitsSkeleton variant={variant} />
      </List>
    )
  }

  if (error || hits.length === 0) {
    return <NoResults />
  }

  return (
    <List
      dataTestId="SearchHitsList"
      variant="results"
      ordered={variant === "ordered-list"}
    >
      {hits.map((hit, index) => {
        const { objectID, type, fields, ...additionalProps } = hit
        const queryID = (searchResults as unknown as SearchResultsExtended)
          .queryID

        const insightClick = () => {
          console.log("INSIGHT CLICK")

          if (queryID) {
            algoliaAnalytics("clickedObjectIDsAfterSearch", {
              index: searchResults.index,
              eventName: "Search Item Clicked",
              queryID: queryID,
              objectIDs: [hit.objectID],
              positions: [index + 1]
            })
          }
        }

        const insightConversion = () => {
          console.log("INSIGHT CONVERT")

          if (queryID) {
            algoliaAnalytics("convertedObjectIDsAfterSearch", {
              index: searchResults.index,
              eventName: "Search Item Converted",
              queryID: queryID,
              objectIDs: [hit.objectID]
            })
          }
        }

        if (!fields) {
          return
        }

        if (type === "post") {
          return (
            <SearchHitPost
              fields={fields as ArticleFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
              {...additionalProps}
            />
          )
        }

        if (type === "article") {
          return (
            <SearchHitArticle
              fields={fields as ArticleFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
              {...additionalProps}
            />
          )
        }

        if (type === "sermon") {
          return (
            <SearchHitSermon
              fields={fields as SermonFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (
          type === "series" ||
          type === "Conference Messages" ||
          type === "Sermon Series" ||
          type === "Teaching Series"
        ) {
          return (
            <SearchHitSeries
              fields={fields as SeriesFields}
              key={objectID}
              forceList={variant !== "cards"}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "qa") {
          return (
            <SearchHitQA
              fields={fields as QuestionAnsweredFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
              typeForIcon={(fields as QuestionAnsweredFields).typeForIcon}
            />
          )
        }

        if (type === "faq") {
          return (
            <SearchHitFaq
              fields={fields as FaqFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "devotional") {
          return (
            <SearchHitDevotional
              fields={fields as DevotionalFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "collection") {
          return (
            <SearchHitCollection
              fields={fields as CollectionFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "event") {
          return (
            <SearchHitConference
              fields={fields as ConferenceFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "teacher") {
          return (
            <SearchHitTeacher
              fields={fields as TeacherFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "mediaResource") {
          const mediaFields = fields as MediaResourceFields
          const additionalProps = mediaResourceMap.get(
            mediaFields.video?.fields?.brightcoveId
          )

          return (
            <SearchHitMediaResource
              fields={mediaFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
              {...additionalProps}
              audioUrl={mediaFields.audio?.fields?.externalAudioUrl}
            />
          )
        }

        if (type === "product") {
          return (
            <SearchHitProduct
              fields={fields as ProductFields}
              description={""}
              key={objectID}
              category={category}
              products={[]}
              variant={variant}
              onClick={insightClick}
              onSearchConversion={insightConversion}
              {...additionalProps}
            />
          )
        }

        if (type === "productCollection") {
          const collectionProps =
            additionalProps as unknown as SearchHitProductCollectionProps

          return (
            <SearchHitProductCollection
              key={objectID}
              onClick={insightClick}
              {...collectionProps}
            />
          )
        }

        if (type === "podcastShow") {
          return (
            <SearchHitPodcastShow
              fields={fields as PodcastShowFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "podcastEpisode") {
          return (
            <SearchHitPodcastEpisode
              fields={fields as PodcastEpisodeFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }

        if (type === "page") {
          return (
            <SearchHitPage
              fields={fields as PageFields}
              key={objectID}
              variant={variant}
              onClick={insightClick}
            />
          )
        }
      })}
    </List>
  )
}

export interface PaginationProps {
  currentRefinement: number
  nbPages: number
  refine: (page: number) => void
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  currentRefinement,
  nbPages,
  refine
}) => (
  <div className={styles.paginationWrapper}>
    {currentRefinement > 1 && (
      <Button
        className={styles.searchHitsButton}
        variant="secondary"
        onClick={() => refine(currentRefinement - 1)}
      >
        <span>Previous</span>
        <span> </span>
        <span>Page</span>
      </Button>
    )}
    {currentRefinement < nbPages && (
      <Button
        className={styles.searchHitsButton}
        variant="secondary"
        onClick={() => refine(currentRefinement + 1)}
      >
        <span>Next</span>
        <span> </span>
        <span>Page</span>
      </Button>
    )}
  </div>
)

export const ConnectedHits = connectStateResults(Hits)
export const ConnectedPagination = connectPagination(Pagination)

export const SearchHits: FunctionComponent<SearchHitsProps> = ({
  className,
  ...props
}) => {
  return (
    <div className={className}>
      <PropsContext.Provider value={props}>
        <ConnectedHits />
        <ConnectedPagination />
      </PropsContext.Provider>
    </div>
  )
}
