import React, { FunctionComponent, useState } from "react"
import { FormattedMessage } from "react-intl"
import { RefinementList } from "react-instantsearch-dom"
import classNames from "classnames"
import { RefinementListProvided } from "react-instantsearch-core"
import { getByTitle } from "@testing-library/react"
import { SearchFilter, SearchAttribute } from "../../search-types"
import { IdToNameMap } from "./search-filter"
import styles from "./search-filter.module.scss"
import { withRefinementList, RefinementItem } from "./with-refinement-list"
import AlgoliaStyleCheckbox from "./algolia-style-checkbox"
import { useFeatureFlag } from "@features/use-feature-flags"
import { ContentLockValues } from "@features/feature-flag-constants"

export interface SearchFilterGroupProps extends SearchFilter {
  className?: string
  translations: IdToNameMap | ((label: string) => string)
  refinementItems: RefinementItem[]
  refine: RefinementListProvided["refine"]
}

interface SingleCheckboxFilterProps {
  attribute: SearchAttribute
  onRefine: RefinementListProvided["refine"]
  label: string
  refinementItems: RefinementItem[]
}

const SingleCheckboxFilter: FunctionComponent<SingleCheckboxFilterProps> = ({
  attribute,
  onRefine,
  label,
  refinementItems
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.target?.checked ? onRefine(["true"]) : onRefine([])
  }

  const isChecked = refinementItems.some((item: any) => {
    return item.isRefined
  })

  return (
    <div className={styles.singleCheckboxFilter}>
      <div className={styles.singleCheckboxContainer}>
        <input
          className={styles.singleCheckboxInput}
          checked={isChecked}
          type="checkbox"
          name={label}
          onChange={handleInputChange}
          id={label}
        />
        <label className={styles.singleCheckboxLabel} htmlFor={label}>
          {label}
        </label>
      </div>
      <AlgoliaStyleCheckbox title={label} checked={isChecked} />
      <hr className={styles.singleCheckboxLine} />
    </div>
  )
}

const SearchFilterGroupComponent: FunctionComponent<SearchFilterGroupProps> = ({
  attribute,
  className,
  title,
  translations,
  refinementItems,
  refine
}) => {
  const contentLockState = useFeatureFlag("contentLock")

  // if this is the 'show free series' checkbox
  // and content lock is set to off, do not render
  if (
    attribute === "fields.freeStream" &&
    contentLockState === ContentLockValues.contentLockOff
  ) {
    return null
  }

  if (!refinementItems?.length) return null

  const isSearchFreeTitle = title === "search-filter-free"

  return (
    <li
      data-testid={`SearchFilterGroup:${title}`}
      className={classNames(styles.searchFilterGroup, className)}
    >
      {!isSearchFreeTitle && (
        <h3 className={styles.searchFilterGroupTitle}>
          <FormattedMessage id={title} />
        </h3>
      )}
      {attribute === "fields.freeStream" ? (
        <SingleCheckboxFilter
          label="Show free series only"
          onRefine={refine}
          attribute={attribute}
          refinementItems={refinementItems}
        />
      ) : (
        <RefinementList
          attribute={attribute}
          limit={5}
          showMore={true}
          showMoreLimit={100}
          transformItems={(items: any) =>
            items.map((item: any) => {
              return {
                ...item,
                label:
                  typeof translations === "function"
                    ? translations(item.label)
                    : item.label
              }
            })
          }
        />
      )}
    </li>
  )
}

export const SearchFilterGroupListItem =
  withRefinementList<SearchFilterGroupProps>(SearchFilterGroupComponent)
