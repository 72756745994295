import React, { FunctionComponent, MouseEventHandler } from "react"
import { PageFields } from "../../../../types/algolia"
import { ListItemPage } from "../../../../components/list/list"
import { SearchResultsVariant } from "../search-results/search-results"
import { CardPage } from "../../../../components/card/card-page"
import { mapTeachers } from "./search-helpers"
import { PageType } from "@components/card/card-constants"

export interface SearchHitPageProps {
  fields: PageFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitPage: FunctionComponent<SearchHitPageProps> = ({
  fields,
  variant,
  onClick
}) => {
  const authors = mapTeachers(fields.teachers || [])
  const pageType = `${fields.pageType}` as PageType
  const pageName = `${fields.searchTitle}`
  const url = `${fields.searchUrl}`
  const image = `${fields.searchImageUrl}`
  const description = `${fields.searchDescription}`
  const previewCopy = fields.previewCopy

  if (variant === "fullcards") {
    return (
      <CardPage
        authors={authors}
        url={url}
        pageType={pageType}
        title={pageName}
        body={description}
        image={image}
        onClick={onClick}
        previewCopy={previewCopy}
      />
    )
  }

  return (
    <ListItemPage
      url={url}
      pageType={pageType}
      title={pageName}
      description={description}
      image={image}
      onClick={onClick}
    />
  )
}
