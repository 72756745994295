import React, { FunctionComponent } from "react"
import {
  ListItemProduct,
  ListItemProductProps
} from "../list/list-item-product"

export const GridItemProduct: FunctionComponent<ListItemProductProps> = (
  props
) => {
  return <ListItemProduct {...props} variant="card" />
}
