import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Card } from "./card"
import styles from "./card-generic.module.scss"
import { Skeleton } from "@components/skeleton/skeleton"
import skeletonStyles from "@components/skeleton/skeleton.module.scss"

export const CardGenericSkeleton: FunctionComponent = () => (
  <Card className={styles.cardGeneric} variant="shadow">
    <div className={classNames(styles.image, skeletonStyles.background)} />
    <div className={styles.content}>
      <div className={styles.textContent}>
        <h2 className={styles.title}>
          <Skeleton />
        </h2>
        <div className={styles.body}>
          <Skeleton count={2} width="100%" />
        </div>
      </div>
    </div>
  </Card>
)
