import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import BackgroundImage from "gatsby-background-image"
import { FormattedMessage } from "react-intl"
import { Icon } from "../../../../components/icon/icon"
import { Avatar } from "../../../../components/avatar/avatar"
import {
  AvatarList,
  AvatarListItemProps
} from "../../../../components/avatar/avatar-list"
import { Link } from "../../../../components/link/link"
import styles from "./card-list-item.module.scss"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"

export interface CardListItemProps {
  messageCount: number
  author?: string
  authors?: AvatarListItemProps[]
  image: string
  title: string
  avatar?: string
  url: string
  forceList?: boolean
  onClick?: MouseEventHandler
}

export const CardListItem: FunctionComponent<CardListItemProps> = ({
  author,
  authors,
  avatar,
  image,
  messageCount,
  title,
  url,
  forceList = false,
  onClick
}) => {
  const fluid = getFluidGatsbyImage(image, { maxWidth: 400 })

  return (
    <Link
      to={url}
      className={classNames(styles.cardListItem, forceList && styles.forceList)}
      onClick={onClick}
    >
      {fluid ? (
        <BackgroundImage className={styles.image} fluid={fluid} />
      ) : (
        <div
          className={classNames(styles.image, styles.placeholder)}
          style={{
            backgroundImage: `url(/assets/image-placeholders/card-logo.svg)`
          }}
        />
      )}
      <div className={styles.text}>
        <div className={styles.meta}>
          <Icon variant="12-video" />
          <span className={styles.messageCount}>
            <FormattedMessage
              id="teaching-series-message-count"
              defaultMessage="{count} messages"
              values={{
                count: messageCount
              }}
            />
          </span>
        </div>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.author}>
          {author && <Avatar image={avatar} name={author} showName size="xs" />}
          {authors && <AvatarList avatars={authors} size="xs" limit={4} />}
        </div>
      </div>
    </Link>
  )
}
