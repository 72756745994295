import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { connectScrollTo } from "react-instantsearch-core"
import {
  defaultSearchFilters,
  defaultSearchIndexes,
  mainSearchIndexName
} from "../../search-constants"
import { SearchFilters, SearchIndexes, SearchConfig } from "../../search-types"
import { SearchHits } from "../search-hits/hits"
import { SearchFilter } from "../search-filter/search-filter"
import { SearchSortDesktop } from "../search-sort/search-sort"
import { SearchHeading } from "../search-heading/search-heading"
import { SearchPanel } from "../search-panel/search-panel"
import { configure } from "../../search-helpers"
import { AlgoliaRecord } from "../../../../types/algolia"
import homeStyles from "./search-results-home.module.scss"
import defaultStyles from "./search-results.module.scss"
import { ResourceType } from "@src-types/resource"

export type SearchResultsVariant =
  | "list"
  | "ordered-list"
  | "cards"
  | "fullcards"
  | "podcast-episodes-list"

export interface SearchResultsProps {
  className?: string
  defaultIndex?: string
  filters?: SearchFilters
  indexes?: SearchIndexes
  hideSort?: boolean
  config?: SearchConfig
  variant?: SearchResultsVariant
  category?: string
  defaultStyleSheet?: boolean
  onDisplay?: (hits: AlgoliaRecord[]) => void
  isWide?: boolean
  value?: any
  hasNotChanged?: boolean
  typeForIcon?: ResourceType
}

const SearchComponent: FunctionComponent<SearchResultsProps> = ({
  className,
  defaultIndex = mainSearchIndexName,
  filters = defaultSearchFilters,
  indexes = defaultSearchIndexes,
  hideSort,
  config,
  variant = "list",
  category,
  defaultStyleSheet = true,
  onDisplay,
  isWide,
  hasNotChanged,
  value: currentPage
}) => {
  const shouldDisplayFilters = filters?.length > 0
  const shouldDisplayIndexes = indexes?.length > 1 && !hideSort

  const styles = defaultStyleSheet ? defaultStyles : homeStyles
  const wrapperElement = useRef<HTMLDivElement>(null)

  const [previousPage, setPreviousPage] = useState<number>(1)

  // user should be scrolled to the top of search results rendered from this component
  // "sort by" should be visable on scroll triggered on next/prev page and/or by entereing any new query
  useEffect(() => {
    if (currentPage !== previousPage && hasNotChanged) {
      setPreviousPage(currentPage)
      wrapperElement?.current?.scrollIntoView()
    }
  })

  // if the isWide type is passed explicitly we use that to determine if wide styles should be applied
  // if not we defer to the presence of a category string which is the previous logic
  const isWideStyle: boolean =
    typeof isWide === "boolean" ? isWide : Boolean(category)

  return (
    <div
      className={classNames(
        styles.searchResults,
        className,
        !shouldDisplayFilters && styles.noFilters,
        isWideStyle && styles.wide
      )}
      id="search-results-wrapper"
      ref={wrapperElement}
    >
      {config && configure(config)}
      <SearchPanel
        className={styles.searchPanel}
        defaultIndex={defaultIndex}
        indexes={indexes}
        filters={filters}
        hideSort={hideSort}
      />
      <SearchHeading className={styles.searchHeading} />
      {shouldDisplayIndexes ? (
        <SearchSortDesktop
          className={styles.searchSort}
          defaultIndex={defaultIndex}
          indexes={indexes}
        />
      ) : (
        <div className={styles.searchSort} />
      )}
      {shouldDisplayFilters && (
        <SearchFilter
          className={styles.searchFilter}
          filters={filters}
          showReset
        />
      )}
      <SearchHits
        className={styles.searchHits}
        variant={variant}
        category={category}
        onDisplay={onDisplay}
      />
    </div>
  )
}

// SearchResults.whyDidYouRender = true

export const SearchResults = connectScrollTo(SearchComponent)
