import React, { ReactElement } from "react"
import classNames from "classnames"
import { DropdownOptions, Option } from "../../types/dropdown"
import { ValidationStatus } from "../../types/validators"
import { Icon, IconVariant } from "../icon/icon"
import { TextInput } from "../text-input/text-input"
import textInputStyles from "../text-input/text-input.module.scss"
import { DropdownOption } from "./dropdown-option"
import { useDropdown } from "./use-dropdown"
import styles from "./dropdown.module.scss"
import storeVariantStyles from "./store-variant.module.scss"
import { StoreDropdownLauncher } from "./store-dropdown-launcher"

export const statusToIconVariant: Record<ValidationStatus, IconVariant> = {
  error: "16-info",
  success: "16-check"
}

export type DropdownVariant = "store" | "site"

export interface DropdownProps<T> {
  className?: string
  defaultOption?: Option<T>
  disabled?: boolean
  ignoreStatus?: boolean
  id: string
  inputClassName?: string
  options: DropdownOptions<T>
  label?: string
  name: string
  onChange?: (selectedItem: Option<T>) => void
  onError?: () => void
  onSuccess?: (selectedItem: Option<T>) => void
  optionClassName?: string
  placeholder?: string
  required?: boolean
  variant?: DropdownVariant
}

export function Dropdown<T = string>({
  className,
  defaultOption,
  disabled = false,
  ignoreStatus = false,
  id,
  inputClassName,
  options,
  label,
  name,
  onChange,
  onError,
  onSuccess,
  optionClassName,
  placeholder,
  required = false,
  variant = "site"
}: DropdownProps<T>): ReactElement {
  const {
    dropdownRef,
    error,
    handleDropdownKeyDown,
    handleInputClick,
    handleOptionSelect,
    highlightedOptionIndex,
    optionsAbove,
    optionsVisible,
    selectedOption,
    status,
    textInputRef
  } = useDropdown<T>({
    defaultOption,
    onChange,
    onError,
    onSuccess,
    options,
    required
  })

  const icon =
    status && !ignoreStatus ? (
      <Icon
        className={textInputStyles.inputIcon}
        id={id && `dropdown-${status}-${id}`}
        variant={statusToIconVariant[status]}
      />
    ) : (
      <Icon
        className={classNames(
          textInputStyles.inputIcon,
          styles.dropdownIcon,
          optionsVisible && styles.rotated
        )}
        id={id && `dropdown-${id}-toggle`}
        onClick={handleInputClick}
        variant="16-right-arrow"
      />
    )

  if (variant === "store")
    return (
      <div
        className={classNames(storeVariantStyles.dropdown, className)}
        ref={dropdownRef}
      >
        <StoreDropdownLauncher
          className={inputClassName}
          ref={textInputRef}
          onClick={handleInputClick}
          label={label}
          placeholder={selectedOption?.label || label || "Select an option"}
        />
        <ul
          className={classNames(
            storeVariantStyles.list,
            optionsVisible && storeVariantStyles.open
          )}
          data-testid="SortDropdownItems"
        >
          {options.map((item, index) => (
            <DropdownOption
              className={storeVariantStyles.option}
              dropdownId={id}
              highlighted={highlightedOptionIndex === index}
              option={item}
              key={item.id}
              onClick={handleOptionSelect}
              selected={item.id === selectedOption?.id}
              hideIcon
            />
          ))}
        </ul>
      </div>
    )

  return (
    <div
      className={classNames(
        styles.dropdown,
        disabled && styles.disabled,
        className
      )}
      onKeyDown={handleDropdownKeyDown}
      ref={dropdownRef}
      data-testid="SortDropdownItems"
    >
      <TextInput
        className={classNames(
          styles.value,
          status && !ignoreStatus && textInputStyles[status],
          inputClassName
        )}
        disabled={disabled}
        icon={icon}
        id={id}
        label={label}
        name={name}
        onClick={handleInputClick}
        placeholder={placeholder}
        readonly
        ref={textInputRef}
        value={selectedOption?.label}
      >
        <ul
          className={classNames(
            styles.optionList,
            optionsVisible && styles.visible,
            optionsAbove && styles.above
          )}
          data-testid="SortDropdownItems"
        >
          {options.map((item, index) => (
            <DropdownOption
              className={optionClassName}
              dropdownId={id}
              highlighted={highlightedOptionIndex === index}
              option={item}
              key={item.id}
              onClick={handleOptionSelect}
              selected={item.id === selectedOption?.id}
            />
          ))}
        </ul>
        {error && <p className={textInputStyles.errorMessage}>{error}</p>}
      </TextInput>
    </div>
  )
}
