import React, { FunctionComponent, MouseEventHandler } from "react"
import { CardProductCollection } from "../../../../components/card/card-product-collection"
import { StoreCollection } from "../../../../core/constants"

// extending with Record type for ...otherProps
export interface SearchHitProductCollectionProps extends Record<string, any> {
  handle: string
  title: string
  products: {
    featuredImage: {
      originalSrc: string
    } | null
  }[]
  onClick?: MouseEventHandler
}

export const SearchHitProductCollection: FunctionComponent<
  SearchHitProductCollectionProps
> = ({ handle, title, products, onClick, ...otherProps }) => {
  const url = `${StoreCollection}/${handle}`
  const images = products.reduce<string[]>((list, { featuredImage }) => {
    if (featuredImage?.originalSrc) {
      list.push(featuredImage.originalSrc)
    }

    return list
  }, [])

  const body = otherProps?.searchDescription

  return (
    <CardProductCollection
      body={body}
      images={images}
      title={title}
      url={url}
      onClick={onClick}
    />
  )
}
