import React, { FunctionComponent, MouseEventHandler } from "react"
import { SearchResultsVariant } from "../search-results/search-results"
import { CollectionFields } from "../../../../types/algolia"
import { ListItemCollection } from "../../../../components/list/list"
import { CardGeneric } from "../../../../components/card/card-generic"
import { PathPrefix } from "../../../../core/constants"
import { formatDatePeriod } from "../../../../i18n/dates"
import { itemCount } from "../../../../core/utils"

export interface SearchHitCollectionProps {
  fields: CollectionFields
  variant: SearchResultsVariant
  onClick?: MouseEventHandler
}

export const SearchHitCollection: FunctionComponent<
  SearchHitCollectionProps
> = ({ fields, variant, onClick }) => {
  const body = fields.description
  const items = itemCount(fields.collectionItems)
  const image = fields.headerImage?.fields?.file.url || ""
  const publicationDate = fields.officialPublicationDate
    ? formatDatePeriod(fields.officialPublicationDate, null)
    : ""
  const url = `${PathPrefix.Collections}/${fields.slug}`

  if (variant === "fullcards") {
    return (
      <CardGeneric
        image={image}
        body={body}
        meta={items}
        icon="12-collection"
        title={fields.title}
        url={url}
        onClick={onClick}
      />
    )
  }

  return (
    <ListItemCollection
      image={image}
      meta={items}
      subtitle={publicationDate}
      title={fields.title}
      url={url}
      onClick={onClick}
    />
  )
}
